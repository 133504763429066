import { Button } from 'antd'
import React from 'react'
import AppleIcon from '../assets/icons/Apple'
import AndroidIcon from '../assets/icons/Android'
import WindowIcon from '../assets/icons/Window'
import { BASE_URL } from '../constants/Url'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export default function DownloadApp(props) {
    const { containerRef, className, title } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className={'select-none relative ' + className} ref={containerRef}>
            <img className='esi-download-background-image' src={BASE_URL + "/download-background/AppBackground.png"} />
            <div className='gap-[20px] p-[1.25rem] md:p-[4rem] h-full relative z-[1] flex flex-col justify-between' >
                {title}
                <div className='flex gap-[1.5rem] lg:gap-[3.5rem] flex-col max-sm:mb-[20px]'>
                    {!title && <div className='ms-[10px] esi-download-title'>
                        {t("Please view more in the App")} <br />
                        {t("Detailed information")}
                    </div>}
                    <div className='flex flex-col gap-3 w-fit'>
                        <img alt='ESI-Logo' role='button' onClick={() => { navigate("/") }} src={BASE_URL + "/logo.png"} className='esi-download-logo' />
                        <div className='ms-[6px] esi-download-description'>{t("The world's leading online hairdressing magazine")}</div>
                    </div>
                </div>
                <div className='esi-download-button-container flex flex-col sm:flex-row gap-3'>
                    <Button size='large' className='esi-btn-apple' icon={<AppleIcon className='esi-apple-icon' color='white' />} >{t("App Store download")}</Button>
                    <Button size='large' className='esi-btn-android' icon={<AndroidIcon className='esi-android-icon' color='white' />}>{t("Android下载")}</Button>
                    <Button size='large' className='esi-btn-window' icon={<WindowIcon className='esi-window-icon' color='black' />}>{t("扫描二维码")}</Button>
                </div>
            </div>
        </div>
    )
}
