import { Modal } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ModalCloseButton from './ModalCloseButton';
import { useTranslation } from 'react-i18next';
import DownloadApp from './DownloadApp';

const AppDownloadModal = (props, ref) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const buttonCloseRef = useRef();
    const containerRef = useRef();

    const showModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const handleForceChangePositionButton = () => buttonCloseRef.current?.changePosition();

    useEffect(() => {
        const handleResize = () => {
            handleForceChangePositionButton()
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useImperativeHandle(ref, () => {
        return { showModal: showModal, closeModal: closeModal }
    })

    return (
        <Modal
            className='esi-download'
            centered
            open={open}
            onOk={false}
            onCancel={() => setOpen(false)}
            width={1000}
            closeIcon={false}
            destroyOnClose={true}
            footer={null}
        >
            <ModalCloseButton
                ref={buttonCloseRef}
                onClose={() => setOpen(false)}
                containerRef={containerRef}
            />
            <DownloadApp
                containerRef={containerRef}
                className="esi-download-container"
            />
        </Modal>
    )
};


export default forwardRef(AppDownloadModal);