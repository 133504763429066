export const API_TOKEN = "JXkWxYaqpvV90S-Z@sIoJxQyxS-ESI";

// Local
// export const API_ENDPOINT = "http://localhost:8098/web";

// Staging
export const API_ENDPOINT = "https://esiproapi.staging.nowbst.com/web";

// QA
// export const API_ENDPOINT = "https://esiproapi.staging.nowbst.com/web";

// Prod
// export const API_ENDPOINT = "https://esiproapi.staging.nowbst.com/web";